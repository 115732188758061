<template>
  <div>
    <a-row v-if="!this.record.isEdit&&!record.logisticsId">
      <a-col :span="11">
        <div class="container">
          <span class="fl required">工具编号：</span>
          <div class="input">
            <a-input v-model="form.licenseNum" placeholder="请输入工具编号"></a-input>
          </div>
        </div>
      </a-col>
      <a-col :span="11" :offset="1">
        <div class="container">
          <span class="fl required">类型：</span>
          <div class="input">
            <a-select size="default" disabled defaultValue="TRUCK" style="width:100%">
              <a-select-option value="TRUCK" style="height:32px">货车</a-select-option>
            </a-select>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="11">
        <div class="container">
          <span class="fl required">司机姓名：</span>
          <div class="input">
            <a-input v-model="form.driverName" placeholder="请输入司机姓名"></a-input>
          </div>
        </div>
      </a-col>
      <a-col :span="11" :offset="1">
        <div class="container">
          <span class="fl required">手机号：</span>
          <div class="input">
            <a-input v-model="form.driverPhone" placeholder="请输入手机号" :maxLength="11"></a-input>
          </div>
        </div>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="6" v-if="record.transType==='TRUCK'">
        <div class="pic-col" >
          <span class="title" :class="{required:record.transType==='TRUCK'}">驾照：</span>
          <div class="pic">
            <img :src="form.drivingLicenseUrl" v-if="form.drivingLicenseUrl" alt=""/>
            <div class="pic-sitg" v-else><a-icon type="plus" :style="{fontSize:'60px',color:'#AAAAAA'}"/></div>
          </div>
          <a-button @click="batchUpload('drivingLicenseUrl')"> <a-icon type="upload" /> 上传 </a-button>
        </div>
      </a-col>
      <a-col :span="6" :offset="1" v-if="record.transType==='TRUCK'">
        <div class="pic-col">
          <span class="title" :class="{required:record.transType==='TRUCK'}">行驶证：</span>
          <div class="pic">
            <img :src="form.carDrivingLicenseUrl" v-if="form.carDrivingLicenseUrl" alt=""/>
            <div class="pic-sitg" v-else><a-icon type="plus" :style="{fontSize:'60px',color:'#AAAAAA'}"/></div>
          </div>
          <a-button @click="batchUpload('carDrivingLicenseUrl')"> <a-icon type="upload"/> 上传 </a-button>
        </div>
      </a-col>
      <a-col :span="6" :offset="1">
        <div class="pic-col">
          <span class="title" :class="{required:record.transType==='TRICYCLE'}">人车合照：</span>
          <div class="pic">
            <img :src="form.carPersonPhotoUrl" v-if="form.carPersonPhotoUrl" alt=""/>
            <div class="pic-sitg" v-else><a-icon type="plus" :style="{fontSize:'60px',color:'#AAAAAA'}"/></div>
          </div>
          <a-button @click="batchUpload('carPersonPhotoUrl')"> <a-icon type="upload" /> 上传 </a-button>
        </div>
      </a-col>
      <a-col :span="6" :offset="1" v-if="record.transType==='TRICYCLE'">
        <div class="pic-col">
          <span class="title" :class="{required:record.transType==='TRICYCLE'}">身份证：</span>
          <div class="pic">
            <img :src="form.idCardFrontUrl" v-if="form.idCardFrontUrl" alt=""/>
            <div class="pic-sitg" v-else><a-icon type="plus" :style="{fontSize:'60px',color:'#AAAAAA'}"/></div>
          </div>
          <a-button @click="batchUpload('idCardFrontUrl')"> <a-icon type="upload" /> 上传 </a-button>
        </div>
      </a-col>
    </a-row>
    <a-row type="flex" justify="center">
      <a-space :size="42">
        <a-button @click="back">返回</a-button>
        <a-button type="primary" @click="handlerOk" :loading="subLoading">提交</a-button>
      </a-space>
    </a-row>

    <input
      v-show="false"
      ref="addBatchImage"
      alt=""
      class="upload"
      type="file"
      name="addImage"
      multiple="multiple"
      accept="image/png,image/jpeg,image/gif,image/jpg"
      @change="getBatchImgFile"/>
  </div>
</template>
<script>
import { uploadPhoto } from '@/api/utils'
import { transportAdd, transportUpdate, transportOrderUpdate } from '@/api/order'
export default {
  props: {
    record: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      form: {
        driverName: '', // 司机姓名
        driverPhone: '', // 联系方式
        licenseNum: '', // 车牌号不能为空
        carDrivingLicenseUrl: '', // 行驶证
        drivingLicenseUrl: '', // 驾驶证
        carPersonPhotoUrl: '', // 人车合照
        idCardFrontUrl: '' // 身份证正面照
      },
      picType: '', // 上传图片类型
      subLoading: false
    }
  },
  mounted () {
    this.form.driverName = this.record.driverName
    this.form.driverPhone = this.record.driverPhone
    this.form.licenseNum = this.record.licenseNum || this.record.plateNumber // plateNumber是货流弹窗数据
    this.form.carDrivingLicenseUrl = this.record.carDrivingLicenseUrl
    this.form.drivingLicenseUrl = this.record.drivingLicenseUrl
    this.form.carPersonPhotoUrl = this.record.carPersonPhotoUrl
    this.form.idCardFrontUrl = this.record.idCardFrontUrl
    this.form.id = this.record.id
  },
  methods: {
    batchUpload (picType) {
      this.picType = picType
      this.$refs.addBatchImage.click()
    },
    back () {
      this.$parent.$parent.$parent.$parent.$parent.$parent.handleOk()
    },
    sub () {
    },
    getBatchImgFile (e) {
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$message.error('请上传正确的格式')
        e.target.value = null
        return
      }
      if ((e.target.files[0].size / 1024 / 1024) > 1.5) {
        this.$message.error('图片大小超过1.5MB，请进行图片压缩处理再上传识别')
        e.target.value = null
        return
      }
      const file = e.target.files[0]
      uploadPhoto(file, (info) => {
        e.target.value = null
        this.form[this.picType] = info
      })
    },
    async handlerOk () {
      const { isEdit } = this.record
      const { logisticsId, transType } = this.record

      if (isEdit && !this.form.licenseNum && !logisticsId) {
        this.$message.error('请输入工具编号！')
        return
      }
      if (!this.form.driverName) {
        this.$message.error('请输入司机姓名')
        return
      }
      var myreg = /^1[3-9][0-9]{9}$/
      if (!this.form.driverPhone||!myreg.test(this.form.driverPhone)) {
        this.$message.error('请输入正确手机号')
        return
      }
      // 火车
      if (this.record.transType === 'TRUCK') {
        if (!this.form.drivingLicenseUrl) {
          this.$message.error('请上传驾驶证')
          return
        }
        if (!this.form.carDrivingLicenseUrl) {
          this.$message.error('请上传行驶证')
          return
        }
      }
      // 三轮车
      if (this.record.transType === 'TRICYCLE') {
        if (!this.form.carPersonPhotoUrl) {
          this.$message.error('请上传人车合照')
          return
        }
        if (!this.form.idCardFrontUrl) {
          this.$message.error('请上传人身份证')
          return
        }
      }

      if (isEdit) {
        // orderLogisticsId存在 是货流弹窗 不需要输入车牌号
        const req = this.form
        let res
        // 如果是 货流 编辑
        if (logisticsId) {
          req.logisticsId = logisticsId
          req.transType = transType
          this.subLoading = true
          res = await transportOrderUpdate(req)
        } else {
          this.subLoading = true
          res = await transportUpdate(req)
        }
        this.subLoading = false
        if (res.code === 200) {
          this.$message.success(res.message)
          this.back()
        } else {
          this.$message.error(res.message)
        }
      } else {
        // if (!this.form.driverName) {
        //   this.$message.error('请输入司机姓名')
        //   return
        // }
        // if (!this.form.driverPhone) {
        //   this.$message.error('请输入手机号')
        //   return
        // }
        const req = this.form
        // 如果是 货流 添加
        if (logisticsId) {
          req.logisticsId = logisticsId
          req.transType = transType
        }
        this.subLoading = true
        transportAdd(req).then(res => {
          this.subLoading = false
          if (res.code === 200) {
            this.$message.success(res.message)
            this.back()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
 .required {
    color: #333;
    &::before {
      display: inline-block;
      margin-right: 2px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
  .container{
    margin-top: 10px;
  }
  .pic-col{
    text-align: center;
    width: 150px;
    padding: 30px 0 40px;
    .pic{
      width: 150px;
      margin: 15px 0;
      padding: 2px;
      border:1px dashed #ccc;
      height: 180px;
      .pic-sitg{
        width: 100%;
        height: 100%;
        background-color: rgba(242, 242, 242, 1);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img{
        width: 100%;
        object-fit:cover;
        height: 100%;
      }
    }
  }
</style>
