<template>
  <div class="pay" >
    <div class="paybodys" :class="bord == true ? 'bord' : ''">
      <p class="fs14">{{title}}</p>
      <a-row type="flex" justify="space-between" v-if="total">
        <a-col :span="2" class="fs30">{{total.total }}</a-col>
        <a-col :span="7">
          <a-progress
            v-if="total.progress"
            type="circle"
            :percent="Number(total.progress.split('%')[0])"
            :format="()=>`${total.progress}`"
            :strokeWidth="20"
            strokeColor="rgb(16, 142, 233)"
            :width="70">
            <template #format="percent">
               <span style="color:rgb(24, 142, 233)">{{percent}}</span>
            </template>
          </a-progress>
        </a-col>
      </a-row>
      <a-divider style="margin: 20px 0 5px 0" />
      <a-row v-if="total">
        <a-col :span="4" class="fs14">已支付</a-col>
        <a-col :span="9" class="fs14"
          ><span class="red">￥</span>{{ total.paid }}</a-col
        >
        <a-col :span="4" class="fs14">未支付</a-col>
        <a-col :span="7" class="fs14"
          ><span class="red">￥</span>{{ total.noPay }}</a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
export default {
  props: ["bord",'title','total'],
  data() {
    return {
    };
  },
};
</script>
<style lang="less" scoped>
p{
  margin: 0;
}
.pay {
  box-sizing: border-box;
  background-color: #fff;
  .paybodys {
    width: 100%;
    height: 154px;
    padding: 10px 20px 0px 20px;
    .fs14 {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.427450980392157);
    }
    .fs30 {
      font-size: 30px;
      color: rgba(0, 0, 0, 0.847058823529412);
      line-height: 70px;
    }
    .red {
      color: red;
    }
  }
  .bord {
    border: 1px solid #ccc;
  }
}
/deep/.ant-col{
  margin: 0 !important;
}
/deep/ .ant-progress-text{
  color: #000 !important;
}
</style>