export default (className,t,Callback={}) => {
    const str = t ? 'addEventListener' : 'removeEventListener'
    const upload = document.querySelector(`.${className}`)
    console.log(upload);
    upload[str]('dragenter', onDrag, false)
    upload[str]('dragover', onDrag, false)
    upload[str]('drop', function (e) {
        console.log('文件', e.dataTransfer.files)
        if (!e.dataTransfer.files.length) return
        e.stopPropagation()
        e.preventDefault()
        // ....
        Callback(e.dataTransfer.files)
    }, false)  
}
// 文件拖拽状态
function onDrag (e) {
    console.log('文件拖拽着')
    e.stopPropagation()
    e.preventDefault()
}