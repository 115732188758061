import request from '@/utils/request'
// import axios from 'axios'

// const baseURL = 'https://www.taxoss.zz91.cn/'
// 上传图片
// export function uploadPhoto(file, callback) {
//     request({
//         url: '/qiniu/token', 
//         method: 'GET',
//         params: {fileName: file.name},
//     }).then(async re => {
//         if (re.code === 200) {
//             const formData = new FormData()
//             formData.append('file', file)
//             formData.append('token', re.data.token)
//             formData.append('key', re.data.key)
//             const res = await axios.post('https://up.qbox.me/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
//             callback(baseURL + re.data.key, res.data)
//         } else {
//             callback()
//         }
//     })
// }

const baseURL = 'https://www.taxoss.zz91.cn/'
// 上传图片
export function uploadPhoto (file, callbackUrl) {
    const formData = new FormData()
    formData.append('file', file)
    request({
        url: '/qiniu/upload',
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
        data: formData
    }).then(async re => {
        if (re.code === 200) {
            callbackUrl(baseURL + re.data, re.data)
        } else {
            callbackUrl()
        }
    })
}

// 上传多张图片
export function uploadBatchPhoto (files, callbackUrl) {
    const formData = new FormData()
    Array.from(files).forEach(item => {
        formData.append('files', item)
    })
    request({
        url: '/qiniu/upload/multiple',
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
        data: formData
    }).then(async (re) => {
        if (re.code === 200) {
        const data = re.data.map(item => {
            return baseURL + item
        })
        callbackUrl(data)
        } else {
        callbackUrl()
        }
    })
}

// 民族
export function nation() {
    const data = [
        '汉族',
        '蒙古族',
        '回族',
        '藏族',
        '维吾尔族',
        '苗族',
        '彝族',
        '壮族',
        '布依族',
        '朝鲜族',
        '满族',
        '侗族',
        '瑶族',
        '白族',
        '土家族',
        '哈尼族',
        '哈萨克族',
        '傣族',
        '黎族',
        '傈僳族',
        '佤族',
        '畲族',
        '高山族',
        '拉祜族',
        '水族',
        '东乡族',
        '纳西族',
        '景颇族',
        '柯尔克孜族',
        '土族',
        '达斡尔族',
        '仫佬族',
        '羌族',
        '布朗族',
        '撒拉族',
        '毛难族',
        '仡佬族',
        '锡伯族',
        '阿昌族',
        '普米族',
        '塔吉克族',
        '怒族',
        '乌孜别克族',
        '俄罗斯族',
        '鄂温克族',
        '崩龙族',
        '保安族',
        '裕固族',
        '京族',
        '塔塔尔族',
        '独龙族',
        '鄂伦春族',
        '赫哲族',
        '门巴族',
        '珞巴族',
        '基诺族',
        '其他',
        '外国血统中国人士',
    ]
    return data
}

export function downloadIamge (imgsrc, name) { // 下载图片地址和图片名
    const image = new Image()
    // 解决跨域 Canvas 污染问题
    image.setAttribute('crossOrigin', 'anonymous')
    image.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      const url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
      const a = document.createElement('a') // 生成一个a元素
      const event = new MouseEvent('click') // 创建一个单击事件
      a.download = name || 'photo' // 设置图片名称
      a.href = url // 将生成的URL设置为a.href属性
      a.dispatchEvent(event) // 触发a的单击事件
    }
    image.src = imgsrc
  }