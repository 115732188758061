import request from '@/utils/request'

// 自然人协议签署
// export function individualLink (params) {
//   return request({
//     url: '/sup/agreement/individual/sign/link',
//     method: 'get',
//     params
//   })
// }

// 合同签署链接(卖方)
export function supplierSignLink (params) {
  return request({
    url: '/trade/agreement/order/supplier/sign/link',
    method: 'get',
    params
  })
}

// 合同签署链接(卖方)
export function purchaserSignLink (params) {
    return request({
      url: '/trade/agreement/order/purchaser/sign/link',
      method: 'get',
      params
    })
  }

  // 委托签署人协议
// export function signerSignLink (params) {
//     return request({
//       url: '/sup/agreement/signer/sign/link',
//       method: 'get',
//       params
//     })
//   }
